// import MoveTo from 'moveto';

class Accordion {
	static MODE_SINGLE = 'single';
	static MODE_MULTI = 'multi';

	element;
	prevOpenItemId;
	interactionLocked = false;

	constructor(el) {
		this.element = el;
		this.isMulti = el.dataset.mode === Accordion.MODE_MULTI;

		Array.from(el.querySelectorAll('[data-accordion-button]')).map(button =>
			button.addEventListener('click', this.onAccordionItemClick)
		);

		// this.moveToMobile = new MoveTo({ tolerance: 200, duration: 600, easing: 'easeOutQuart' });
		this.checkForInitOpenItems();
	}

	checkForInitOpenItems() {
		let items = Array.from(this.element.querySelectorAll('[data-accordion-init-open]'));

		if (!this.isMulti && items.length > 0) {
			items = [items.shift()];
		}

		items.forEach(item => {
			const id = +item.dataset.accordionButton;
			this.openItemById(id);
			this.prevOpenItemId = id;
		});
	}

	onAccordionItemClick = ({ currentTarget: target }) => {
		if (this.interactionLocked) return;

		const accordionItemId = +target.dataset.accordionButton;
		this.closeCurrentNodeIfNecessary(accordionItemId);

		this.toggleItemById(accordionItemId);
	};

	closeCurrentNodeIfNecessary(nextItemToOpenId) {
		if (this.prevOpenItemId == null || this.prevOpenItemId === nextItemToOpenId || this.isMulti)
			return;
		this.closeItemById(this.prevOpenItemId);
	}

	closeItemById(id) {
		const accordionItem = this.element.querySelector(`[data-accordion-item="${id}"]`);
		const accordionContent = document.querySelector(`[data-accordion-content="${id}"]`);

		accordionContent.style.maxHeight = accordionContent.dataset.prevMaxHeight || null;
		accordionContent.removeAttribute('data-prev-max-height');

		window.requestAnimationFrame(() => {
			window.requestAnimationFrame(() => {
				accordionItem.classList.remove('is-open');
				accordionContent.style.maxHeight = null;
			});
		});
	}

	openItemById(id) {
		const accordionItem = this.element.querySelector(`[data-accordion-item="${id}"]`);
		const accordionContent = document.querySelector(`[data-accordion-content="${id}"]`);

		accordionContent.addEventListener('transitionend', this.onOpenTransitionEnd);
		accordionContent.addEventListener('webkitTransitionEnd', this.onOpenTransitionEnd);

		accordionItem.classList.add('is-open');
		const height = accordionContent.scrollHeight;
		accordionContent.style.maxHeight = `${height}px`;

		// this.moveToItem(accordionContent);
	}

	onOpenTransitionEnd = ({ target }) => {
		target.dataset.prevMaxHeight = target.style.maxHeight;
		target.style.maxHeight = 'initial';

		target.removeEventListener('transitionend', this.onOpenTransitionEnd);
		target.removeEventListener('webkitTransitionEnd', this.onOpenTransitionEnd);

		this.interactionLocked = false;
	};

	toggleItemById(id) {
		const accordionItem = this.element.querySelector(`[data-accordion-item="${id}"]`);
		if (accordionItem.classList.contains('is-open')) {
			this.closeItemById(id);
			this.prevOpenItemId = null;
		} else {
			this.openItemById(id);
			this.interactionLocked = true;
			this.prevOpenItemId = id;
		}
	}

	// moveToItem(node) {
	// 	if (window.innerWidth > 768) return;

	// 	setTimeout(() => {
	// 		this.moveToMobile.move(node);
	// 	}, 250);
	// }
}

Array.from(document.querySelectorAll('.js-accordion')).forEach(el => new Accordion(el));
